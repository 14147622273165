import 'react-calendar/dist/Calendar.css';
import { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import NotFound from './pages/404';
import Art from './pages/Art';
import Music from './pages/Music';
import Section from './pages/MusicSection';
import { System, ChildSytstem } from './pages/System';
import request from './request';

const Employee = lazy(() => import("./pages/Employee"))
const Child = lazy(() => import("./pages/ExamplaryPage"))


const Home = lazy(() => import("./pages/Home"))
const General = lazy(() => import("./pages/General"))
const Reseption = lazy(() => import("./pages/Reseption"))
const News = lazy(() => import("./pages/News"))
const NewsItem = lazy(() => import("./pages/NewsItem"))
const Announcements = lazy(() => import("./pages/Announcements"))
const SelectedAnnouncement = lazy(() => import("./pages/Announcement"))
const Contact = lazy(() => import("./pages/Contact"))
const Footer = lazy(() => import("./components/Footer"))
const Header = lazy(() => import("./components/Header"))
const About = lazy(() => import("./pages/About"))
const School = lazy(() => import("./pages/School"))
const ScientificPage = lazy(() => import("./pages/ScientificPage"))
const ScientificItem = lazy(() => import("./pages/ScientificItem"))


function App() {
  const {theme} = useSelector((state) => state.theme)
  const [sections, setSection] = useState()
  const [general, setGeneral] = useState()

  useEffect(()=> {
    request.get(`/department/getAll`).then(data => {
      if(data.status === 200) {
        const result = []
        const reqData = data?.data?.data
        reqData.map((section) => {
          if(section?.name.match(/umumta'lim/gi)){
            result.push(section)
          } else if(section?.name.match(/rassom/gi)) {
            result.unshift(section)
          } else if(section?.name.match(/musiqa/gi)) {
            result.splice(1,0,section)
          }
        })
        setSection(result)
      }
    })
    request.get(`/general`).then(data => {
      if(data.status === 200 && data?.data?.data?.length) {
        setGeneral(data?.data?.data[0])
      }
    })    
  },[])

  return (
    <div className={`App d-flex flex-column justify-content-between`} style={{minHeight: "100vh"}}>

      <Header general={general} sections={sections}/>
      <div className='flex-grow-1'>
        <Routes>
          <Route path='/general/:id' Component={General}/>
          <Route path='/music/:id' Component={Music}>
            <Route {...{path: `direction/:directionId`, element: <Section />}}/>
          </Route>
          <Route path='/system' Component={System}>
            <Route path=':systemId' element={<ChildSytstem/>}/>
          </Route>
          <Route path='/art/:id' Component={Art}/>
          <Route path='/contact' Component={Contact}/>
          <Route path='/reseption' Component={Reseption}/>
          <Route path='/news' Component={News}/>
          <Route path='/news/:id' Component={NewsItem}/>
          <Route path='/science' Component={ScientificPage}/>
          <Route path='/science/:id' Component={ScientificItem}/>
          <Route path='/system' Component={System}/>
          <Route path='/employee/:id' Component={Employee}/>
          <Route path='/child/:id' Component={Child}/>
          <Route path='/announcements' Component={Announcements}/>
          <Route path='/announcements/:id' Component={SelectedAnnouncement}/>
          <Route path='/about' Component={About}/>
          <Route path='/school' Component={School}/>
          <Route path='/' Component={Home}/>
          <Route path='*' Component={NotFound} />
        </Routes>        
      </div>
      <Footer general={general} sections={sections}/>
    </div>
  );
}

export default App;
