import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {Collapse} from 'react-collapse';
import request from '../../request';

const System = () => {
  const {systemId} = useParams()
  const [collapseStatus, setCollapseStatus] = useState();
  const [directions, setDirections] = useState();
  const collapseRef = useRef();
  const navigate = useNavigate();
  
  useEffect(() => {
    request.get(`/department/getAll`).then(response => {
      if (response?.status === 200) {
        const rahbariyatId = response?.data?.data?.find((section)=> section.name.toLowerCase().includes("rahbariyat"))        
        request.get(`/department/getAllDirectionByDepartment?id=${rahbariyatId?.id}`).then(data => {
          if (data?.status === 200) {
            const myData = data?.data?.data
            setDirections(myData)
            if (!systemId) {
              navigate(`/system/${myData?.[0]?.direction_id}`, {replace: true})              
            }
          }
        })
      }
    })
  },[]);

  useEffect(() => {
    function NavStatus() {
      if (document.body.clientWidth < "992") {
        setCollapseStatus("");
      } else {
        setCollapseStatus("show");
      }
    }
    NavStatus()
    window.addEventListener("resize", NavStatus);
    return () => {
      window.removeEventListener("resize", NavStatus);
    }
  }, []);

  function closeCollapse(){
    if (document.body.clientWidth < "992") {
      collapseRef?.current.click()
    }
  }

  return (
    <>
      <div className="system d-flex align-items-center justify-content-center mb-4 flex-grow-1">
        <h1 className="text-white fw-700">RAHBARIYAT</h1>
      </div>
      <div className="container pb-5">
        <div className="row g-2">
          <div className="col-12 col-lg-4 text-darkblue2">
            <div className="accordion bg-light" id="accordionSystem">
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom">
                  <button 
                    ref={collapseRef} 
                    className="accordion-button fw-700" 
                    style={{fontFamily: "Montserrat"}} 
                    type="button" data-bs-toggle="collapse" 
                    data-bs-target={`#collapse_systemSection`} 
                    aria-expanded="true" 
                    aria-controls={`collapse_systemSection`}>
                    Rahbariyat
                  </button>
                </h2>
                <div id={`collapse_systemSection`} className={`accordion-collapse collapse ${collapseStatus}`} data-bs-parent="#accordionSystem">
                  <div className="accordion-body">
                  {
                    directions?.length
                      ? directions.map((direction, key)=> (
                        <Link to={`/system/${direction?.direction_id}`} key={key} className={`d-block fs-18 text-darkblue py-2 ${systemId == direction?.direction_id ? "fw-700" : ""}`} onClick={closeCollapse}>{direction?.direction_name}</Link>
                      )) : null
                  }
                  </div>
                </div>
              </div>            
            </div>  
          </div>
          {<Outlet />}
        </div>
      </div>
    </>
  );
};

function ChildSytstem () {
  const {systemId} = useParams();
  const [system, setSystem] = useState();

  useEffect(()=>{
    if(systemId) {
      getDirections(systemId)
    }
  },[systemId]);

  function getDirections(id) {
    request.get(`/employee/direction/${id}`).then(response => {
      if (response?.status === 200) {
        const myEmp = response?.data?.data[0]
        console.log(myEmp, " ...sdssssssssssssss");
        
        if (myEmp) {
          setSystem(myEmp)
          document.getElementById(`system_editor`).innerHTML = "";
          document.getElementById(`system_about`).innerHTML = "";
          document.getElementById(`system_editor`).insertAdjacentHTML('afterbegin', myEmp?.editor);
          document.getElementById(`system_about`).insertAdjacentHTML('afterbegin', myEmp?.about);
          
        } else {
          setSystem(null)
          document.getElementById(`system_editor`).innerHTML = ""
          document.getElementById(`system_about`).innerHTML = ""
        }            
      }
    })
  }  

  return (
    <div className="col-12 col-lg-8">
      <div className="bg-light p-3">
        <div className='fs-22 fw-500 text-center' style={{fontFamily: "Montserrat"}}>{system?.direction_name}</div>              
        <div className='row g-3'>
          <div className='col-12 col-sm-4 d-flex justify-content-center'>
            {
              system
              ? (
                <img src={system?.photoLink} alt={system?.fullName} width={"100%"} style={{aspectRatio: "3 / 4", objectFit: "contain", maxWidth: "400px"}}/>
              ) : null
            }
          </div>
          <div className="col-12 col-sm-8">                 
            <div id="system_editor"></div>
          </div>
          <div className='text-darkblue'>
            <div id="system_about"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  System,
  ChildSytstem
};