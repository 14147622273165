import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className='flex-grow-1 d-flex align-items-center justify-content-center mt-90 mb-20'>
        <div>
          <div className="px-4 py-5 fs-30 lh-40 border border-footer rounded">404 Not Found</div>
          <Link to={"/"} className="btn btn-outline-footer fs-20 w-100 mt-3 text-footer text-center">Orqaga</Link>
        </div>
    </div>
  )
}

export default NotFound;