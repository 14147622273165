import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import request from '../../request';
import prezident from "../../assets/images/pr.jpg"

const Music = () => {
  const { id, directionId } = useParams()
  const [collapseStatus, setCollapseStatus] = useState();
  const collapseRef = useRef();
  const navigate = useNavigate()
  const [directions, setDirections] = useState()  
  const outputRef = useRef()

  useEffect(() => {
    if (id) {
      request.get(`/department/getAllDirectionByDepartment?id=${id}`).then(data => {
        if (data?.status === 200) {
          const myData = data?.data?.data;
          setDirections(myData)
          if (!directionId) {
            navigate(`/music/${id}/direction/${myData[0]?.direction_id}`, {replace: true})
          }
        }
      })
    }
  }, [id]);

  useEffect(() => {
    function NavStatus() {
      if (document.body.clientWidth < "992") {
        setCollapseStatus("");
      } else {
        setCollapseStatus("show");
      }
    }
    NavStatus()
    window.addEventListener("resize", NavStatus);
    return () => {
      window.removeEventListener("resize", NavStatus);
    }
  }, []);

  function closeCollapse(){
    if (document.body.clientWidth < "992") {
      collapseRef?.current.click()
    }
  }
  return (
    <>
      <div className="music-section d-flex align-items-center justify-content-center">
        <h1 className="text-white fw-700 fs-26">MUSIQA BO'LIMI</h1>
      </div>
      <div className='container py-4'>
        {/* <div className='border-bottom border-top border-success2 rounded px-5 py-4 bg-light'>
          <div className='fs-22 fst-italic mb-3 fw-700 text-center text-darkblue2'>So'zda sehr, she'rda hikmat, tasvirda sayqal, kuyda go'zallik bor. So'z tugagan joyda musiqa boshlanadi...</div>
        </div> */}
        <div className="row g-0">
          <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-0">
            <img src={prezident} alt="prezident" width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-7 offset-lg-0 fs-18 lh-24">
            <div className='d-flex flex-column justify-content-center text-white fst-italic bg-darkblue py-4 px-2 px-lg-4 h-100'>
              <div>Musiqa san'ati madaniy fenomen sifatida yangi avlodni tarbiyalash va kamolga yetqazish borasida cheksiz imkoniyatlariga egadir. </div>
              <div className="text-end text-success2 mt-3">Shavkat Mirziyoyev, <br />O‘zbekiston Respublikasi Prezidenti.</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="row gy-4">
          <div className="col-12 col-xl-3">
            <div className="accordion bg-light" id="accordion-music-section">
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom">
                  <button ref={collapseRef} className="accordion-button fw-700" style={{ fontFamily: "Montserrat" }} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_musicSection`} aria-expanded="true" aria-controls={`collapse_musicSection`}>
                    Yo'nalishlar
                  </button>
                </h2>
                <div id={`collapse_musicSection`} className={`accordion-collapse collapse ${collapseStatus}`} data-bs-parent="#accordion-music-section">
                  <div className="accordion-body">
                    {directions?.length ? directions.map((dir, key) => (
                      <div className='my-2' key={key} onClick={closeCollapse}>
                        <Link to={`/music/${id}/direction/${dir?.direction_id}`} className={`${directionId == dir?.direction_id ? "fw-700" : ""} fs-18 text-darkblue`}> {dir?.direction_name}</Link>
                      </div>
                    )) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={outputRef} className="col-12 col-xl-9">{<Outlet />}</div>
        </div>
      </div>
    </>
  );
};

export default Music;