import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import request from '../../request';

const Section = () => {
  const {directionId} = useParams()
  const [subjects, setSubjects] = useState([])
  const [employee, setEmployee] = useState([])

  useEffect(()=> {
    if(directionId) {
      request.get(`/direction/${directionId}`).then(data => {
        if(data?.status === 200){
          const output = document.getElementById('output-content')
          output.innerHTML = ""
          output.insertAdjacentHTML('afterbegin', data?.data?.data?.history)
        }
      })

      request.get(`/subject/direction/${directionId}`).then(data => {
        if(data.status === 200) {
          setSubjects(data?.data?.data)
        }
      })

      request.get(`/employee/direction/${directionId}`).then(data => {
        if(data.status === 200) {
          setEmployee(data?.data?.data)
        }
      })
    }
  },[directionId])
  
  return (   
    <div>
      {
        subjects?.length 
        ? <div className="mb-3 fs-26 fw-600 lh-36 text-darkblue opacity-75 text-center">Mutaxassislik</div>
        : null
      }
      
      <ul className='row g-2'>
        {
          subjects?.length ? subjects.map((sub, key)=>(
            <li key={key} className="col-12 col-sm-6 col-md-4">
              <div className='h-100 music-card border border-darkblue text-center p-3 text-text1'>
                {sub?.name}
              </div>
            </li>
          )) : null
        }
      </ul>
      <p id="output-content" className="sun-editor-editable border-0 bg-transparent my-5"></p>
      <div className="my-3 fs-26 fw-600 lh-36 text-darkblue opacity-75">Jamoa</div>
      <div className="row g-2">
        {
          employee?.length ? employee.map((emp, key)=>(
            <Link key={key} to={`/employee/${emp?.id}`}className="col-12 col-lg-6">
              <div className="card-shadow bg-light px-3 py-1 border-success2 border-top border-bottom">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="d-flex justify-content-center" style={{aspectRatio: "3 / 4", overflow: "hidden"}}>
                      <img src={emp?.photoLink} alt={emp?.fullName}  height={"100%"}/>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="fs-18 lh-26 fw-600 text-darkblue">{emp?.fullName}</div>
                    <div className="fs-16 text-success2 fw-500 mb-4">{emp?.position_name}</div>
                    {/* <div className="d-flex align-items-center justify-content-center p-2 w-34 opacity-75 bg-darkblue rounded-circle"><i class="fab fa-facebook-f text-white"></i></div> */}
                  </div>
              </div>
              </div>
            </Link>
          )) : null
        }
      </div>
    </div>
  );
};

export default Section;